import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
// import image from '../../assets/images/items/3.gif';
import image1 from '../../assets/images/avatar/1.jpg';
import image3 from '../../assets/images/items/2.gif';
import image4 from '../../assets/images/items/1.jpg';
import image5 from '../../assets/images/items/2.jpg';
import image6 from '../../assets/images/items/1.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import { data } from '../../data/data';
import img1 from '../../assets/images/education.png'
import {IoMdClose,BsExclamationOctagon} from "../../assets/icons/vander"

export default function EducationResource() {

    const image = 'https://riseangle.com/storage/events/obK5L1eKYqxlK8qnJ2lWRT7W0MLKA6H7fn8Z3QBJ.gif'

    const params = useParams();
    const id = params.id
    const creater = data.find((creatorr) => creatorr.id === parseInt (id));

    const [activeIndex, setIndex] = useState(0);
    const [placeBid , setPlaceBid] = useState(false)
    const [ buyNow, setBuyNow] =  useState(false)

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    // const img1 = 'https://digitalunik.com/wp-content/uploads/2023/10/58c14df9-7e83-4f1e-a79b-7a4f54fe6b77.jpeg'

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic4">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">Educational <span style={spanStyle}> Resources</span></h4>
                        <p className="text-slate-400 max-w-xl mx-auto">Learning with Joy</p>

                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> 

            <section className="relative pt-28 md:pb-24 pb-16">
                <div className="container">
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-5">
                            <img src={creater?.image ? creater?.image : image} className="rounded-md shadow dark:shadow-gray-700" alt="" />
                        </div>

                        <div className="lg:col-span-7 lg:ms-8">
                            <h5 className="md:text-3xl text-2xl font-semibold"><span style={spanStyle}>Dive into the World </span> of AI and Blockchain</h5>
                            <p className="text-slate-400 mt-4">Our educational section provides comprehensive tutorials and guides on AI, blockchain technology, and NFTs. Perfect for both beginners and experienced users, these resources are designed to enhance your understanding and skills.</p>
                            <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                
                                    <div className="flex items-center">
                                        <div className="ms-3">
                                            <Link to="#" className="font-bold text-2xl lg:text-3xl mb-3 text-violet-600 block hover:text-violet-600">Interactive Learning for Hands-On Experience</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Engage with our interactive modules that offer practical, real-world applications. From building your first AI model to understanding the intricacies of NFT creation, our modules make learning engaging and effective</span>
                                        </div>
                                    </div>
                                
                            </div>

                            <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                
                                    <div className="flex items-center">
                                        <div className="ms-3">
                                            <Link to="#" className="font-bold text-2xl lg:text-3xl mb-3 text-violet-600 block hover:text-violet-600">Learn from the Community</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Tap into the wealth of knowledge within our community. Participate in webinars, community-led workshops, and discussions to learn from peers and experts in the field</span>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`fixed z-50 overflow-hidden inset-0 m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${placeBid ? "" : "hidden" }`}>
                <div className="relative w-full h-auto max-w-md p-4">
                    <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                            <h5 className="text-xl font-semibold">Place a Bid</h5>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" >
                                <IoMdClose onClick={()=> setPlaceBid(!placeBid)}/>
                            </button>
                        </div>
                        <div className="p-6">
                            <form className="text-start">
                                <div className="grid grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-semibold" >Your Bid Price:</label>
                                        <input name="etherium" id="number" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="00.00 ETH" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="font-semibold" >Enter Your QTY:</label>
                                        <input name="quantity" id="number2" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="0"/>
                                        <span className="text-slate-400 text-sm"><span className="text-slate-900 dark:text-white mt-1">Note:</span> Max. Qty 5</span>
                                    </div>
                                </div>
                            </form>

                            <div className="pt-4 border-t dark:border-t-gray-800">
                                <div className="flex justify-between">
                                    <p className="font-semibold text-sm"> You must bid at least:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.22 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Service free:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 0.05 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Total bid amount:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.27 ETH </p>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" data-modal-toggle="NftBid" className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white w-full"><i className="mdi mdi-gavel"></i> Place a Bid</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div  className={`fixed z-50 overflow-hidden inset-0 m-auto justify-center items-center flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 ${buyNow ? "" :"hidden"}`}>
                <div className="relative w-full h-auto max-w-md p-4">
                    <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-100 dark:border-gray-700">
                            <h5 className="text-xl font-semibold">Checkout</h5>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" >
                                <IoMdClose  onClick={() => setBuyNow(!buyNow)}/>
                            </button>
                        </div>
                        <div className="p-6">
                            <form className="text-start">
                                <div className="grid grid-cols-1">
                                    <div className="mb-4">
                                        <label className="font-semibold" >Your Price:</label>
                                        <input name="etherium" id="number3" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-3" placeholder="00.00 ETH" />
                                    </div>
                                </div>
                            </form>

                            <div className="pt-4 border-t dark:border-t-gray-800">
                                <div className="flex justify-between">
                                    <p className="font-semibold text-sm"> You must bid at least:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.22 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Service free:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 0.05 ETH </p>
                                </div>
                                <div className="flex justify-between mt-1">
                                    <p className="font-semibold text-sm"> Total bid amount:</p>
                                    <p className="text-sm text-violet-600 font-semibold"> 1.27 ETH </p>
                                </div>
                            </div>

                            <div className="flex items-center p-4 bg-red-600/10 text-red-600 mt-4 rounded-lg">
                                <BsExclamationOctagon className="text-3xl"/>

                                <div className="ms-2">
                                    <span className="block font-semibold">This creator is not verified</span>
                                    <span className="block">Purchase this item at your own risk</span>
                                </div>
                            </div>

                            <div className="mt-4">
                                <Link to="#" className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white w-full"><i className="mdi mdi-lightning-bolt"></i> Buy Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer />
        </>
    )
}
