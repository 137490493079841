import React, { useState } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
// import img1 from '../assets/images/howdemo.jpeg'
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import img1 from '../../src/assets/images/howit.png'
import {MdOutlineArrowForward} from "../assets/icons/vander"

export default function Howitworks() {
    
    const accordionData = [
        {
            title: 'How does it work ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Do I need a designer to use Giglink ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What do I need to do to start selling ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What happens when I receive an order ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
    ]
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`, backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic6">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">How It <span style={spanStyle}> Works</span></h4>
                        <p className="text-slate-400 max-w-xl mx-auto">Navigating Joy Marketplace</p>

                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> 
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h5 className="text-xl font-semibold mb-4">Begin Your Journey with Joy :</h5>
                                <p className="text-slate-400">Embark on an intuitive journey with Joy. Start by creating your account, and let our Copilot AI guide you through the marketplace. Discover how easy it is to browse AI models, view NFT art, and engage with our community</p>

                                <h5 className="text-xl font-semibold mb-4 mt-7">Create, Upload, and Trade with Ease :</h5>
                                <p className="text-slate-400">Transform your AI models into visual masterpieces and trade them as NFTs. Our platform simplifies the process of uploading your AI models, turning them into digital art, and trading them securely within the Joy community.</p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">Connect and Collaborate :</h5>
                                {/* <p className="text-slate-400">The most well-known dummy text is the 'Lorem Ipsum', which is said to have <b className="text-red-600">originated</b> in the 16th century. Lorem Ipsum is <b className="text-red-600">composed</b> in a pseudo-Latin language which more or less <b className="text-red-600">corresponds</b> to 'proper' Latin. It contains a series of real Latin words. This ancient dummy text is also <b className="text-red-600">incomprehensible</b>, but it imitates the rhythm of most European languages in Latin script. The <b className="text-red-600">advantage</b> of its Latin origin and the relative <b className="text-red-600">meaninglessness</b> of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's <b className="text-red-600">attention</b> from the layout.</p> */}
                                <p className="text-slate-400 mt-3">Dive into the heart of <b className="text-red-600">Joy–our community </b> Participate in discussions, <b className="text-red-600">collaborate on projects,</b> and contribute to DAO governance. Discover how <b className="text-red-600">Joy brings together creators, </b> innovators, and enthusiasts in a vibrant ecosystem.</p>
                                {/* <p className="text-slate-400 mt-3">It seems that only <b className="text-red-600">fragments</b> of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text.</p> */}

                                <h5 className="text-xl font-semibold mb-4 mt-8"> How It Works :</h5>
                                <p className="text-slate-400">Your Guide to Joy Marketplace</p>
                                <ul className="list-none text-slate-400 mt-3">
                                <p className="text-red-600" style={{marginBottom:'-20px'}}>Step 1 : Start Your Journey in AI Innovation</p><br></br><li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Begin by exploring the vast array of AI models and digital artworks represented as NFTs. Our intuitive interface makes it easy to navigate and discover the perfect resources for your needs</li>
                                <p className="text-red-600 mt-3" style={{marginBottom:'-20px'}}>Step 2 : Unleash Your Creativity</p><br></br><li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Utilize our integrated tools like Figma and VS Code to create or customize AI models. With Copilot’s guidance, even beginners can easily develop and refine AI projects.</li>
                                <p className="text-red-600 mt-3" style={{marginBottom:'-20px'}}>Step 3 : Connect, Trade, and Grow</p><br></br><li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Engage with the community to collaborate and gain insights. Trade your AI model NFTs securely, with blockchain technology ensuring transparent and fair transactions</li>
                                <p className="text-red-600 mt-3" style={{marginBottom:'-20px'}}>Step 4 : Shape the Future of Joy</p><br></br><li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Join the Sustainable Loving DAO and have a say in the governance of the marketplace. Your contributions help shape Joy’s growth and direction.</li>                                    
                                </ul>

                                {/* <h5 className="text-xl font-semibold mt-8">Users Question & Answer :</h5> */}

                                {/* <div id="accordion-collapse" data-accordion="collapse" className="mt-6">
                                    {accordionData.map((item, index) => (
                                        <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                            <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                                <button onClick={() => toggleAccordion(index)} type="button"
                                                    className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-violet-600' : ''}`}
                                                    data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                                    <span>{item.title}</span>
                                                 
                                                    <svg data-accordion-icon className="w-4 h-4 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </h2>
                                            {activeIndex === index && (
                                                <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                                    <div className="p-5">
                                                        <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div> */}

                                <div className="mt-6">
                                    {/* <Link to="#" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full">Accept</Link> */}
                                    <Link to="#" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white ms-2" style={{borderRadius:'6px'}}>Join With Joy Marketplace</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
