import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { data1 } from '../data/data';
import { images } from '../data/data';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight, FiUserPlus} from "../assets/icons/vander"

import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';

export default function CreatorTwo(props) {
    
    return (
        
        <div className='container '>
            <div className="grid grid-cols-1 text-center" style={{marginTop:'-30px'}}>               
                <h1 className="font-bold lg:leading-snug leading-snug text-3xl lg:text-3xl mb-4">Testimonial</h1>
            </div>
            <div className='grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 gap-[30px] mt-10'>
                
            {
                data1.slice(0,3).map((item, index) => (
                    <div key={index} className="group relative overflow-hidden bg-gray-50 dark:bg-slate-800 rounded-md shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 ease-in-out duration-500 hover:-mt-2 h-fit">
                        <div className="flex justify-between items-center p-3">
                            <div className="flex items-center">
                                <div className="relative inline-block">
                                    <img src={item.image} className="h-16 rounded-md" alt="" />
                                    <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                                </div>

                                <div className="ms-3">
                                    <Link to={'#'} className="font-semibold block hover:text-violet-600">{item.name}</Link>
                                    <span className="text-slate-400 text-sm block mt-1">{item.subtext}</span>
                                </div>
                            </div>
                        </div>

                        <div className="p-2 border-t dark:border-t-gray-700">
                            <div className="flex items-center">
                                {
                                    item.subImage.map((subitem, index) => (
                                        <div className="ms-3" key={index}>
                                            
                                                <span className="text-slate-400 text-sm block mt-1">{subitem.content}</span>
                                            
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        
        </div>      
           
        </div>
    )
}
