import React, { useState,useEffect } from 'react'
import metamask from '../assets/images/wallet/metamask.png';
import alpha from '../assets/images/wallet/alpha.png';
import binance from '../assets/images/wallet/binance.png';
import bitski from '../assets/images/wallet/bitski.png';
import coinbase from '../assets/images/wallet/coinbase.png';
import enjin from '../assets/images/wallet/enjin.png';
import formatic from '../assets/images/wallet/formatic.png';
import pionex from '../assets/images/wallet/pionex.png';
import torus from '../assets/images/wallet/torus.png';
import trustwallet from '../assets/images/wallet/trustwallet.png';
import connect from '../assets/images/wallet/wallet-connect.png';
import Navbar from '../components/navbar'
import {LuClock, LuSearch, AiOutlineAppstore, AiOutlineStar} from "../assets/icons/vander"
import Select from 'react-select'
import Footer from '../components/footer';
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import {MdOutlineArrowForward} from "../assets/icons/vander"
import { Facebook, Instagram, Linkedin, Youtube, Twitter } from 'react-feather';
import Modal from 'react-modal';


export default function CreateNFTCards() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to toggle the modal visibility
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const Basic = 'https://miro.medium.com/v2/resize:fit:679/0*f9iPuv2fhOgAMyQn.gif'
    const Second = 'https://i.pinimg.com/originals/e7/f5/00/e7f500f0ee861d58826836cc5e63a2ce.gif'
    const Third = 'https://media3.giphy.com/media/mbVV6Bl0FSmCQOJEoS/200w.gif?cid=82a1493b78sf6jnkvdaue4ibqfrokcr3shiqrgbi7y4ptibv&ep=v1_gifs_related&rid=200w.gif&ct=g'
    const Fourth = 'https://media2.giphy.com/media/IOfMu6BapZcVsmHKZU/giphy.gif?cid=6c09b952cj26pj6kt3a7rwqyalpwj8gs79v6fog1sszg93ns&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=g'
    const Fifth = 'https://airnfts.s3.amazonaws.com/nft-images/20220321/11_GIF_1647858995373.gif'
    const Sixth = 'https://media1.giphy.com/media/kh8H1khiBpOteZiyfB/giphy.gif?cid=6c09b952cjrk093erzcsozjv5vmbd7y8q5kkp1zoldmsdu7f&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=g'
    const Seventh = 'https://unlock-protocol.com/images/blog/dynamic-nft/tales-of-elatora.gif'
    const Eight = 'https://images.ctfassets.net/nccdc912q1to/1VYtqnlliiDxMxPiHjSC1q/137f76e8314d36a29a3e7ecf881c38c6/bear-2.gif'
    const Nine = 'https://cdn.pixabay.com/animation/2023/05/02/15/28/15-28-22-399_512.gif'
    const Robotic = 'https://miro.medium.com/v2/resize:fit:768/1*pfa6G8N0TV126Xo1AFZPew.gif'
    const Robotic6 = 'https://openseauserdata.com/files/5f085809f2b711643e4eb4974cc03c0e.gif'
    const Robotic7 = 'https://openseauserdata.com/files/06bedfb3ed400da352159a4e4807645d.gif'

    const options = [
        { value: 'Digital Art:', label: 'Digital Art:' },
        { value: 'Videos', label: 'Videos' },
        { value: 'Music', label: 'Music' },
        { value: 'Interactive Art', label: 'Interactive Art' },
        { value: 'AI Code', label: 'AI Code' },
    ];
    
    const buyOptions = [
        { value: 'Deep Dream Art', label: 'Deep Dream Art' },
        { value: 'Neural Network Compositions', label: 'Neural Network Compositions' },
        { value: 'Deepfake Art', label: 'Deepfake Art' },
        { value: 'AI-Generated Game Assets', label: 'AI-Generated Game Assets' },
        { value: 'Processing Sketches', label: 'Processing Sketches' },
    ]

    const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isHovered ? '#1e293b' : '#1e293b',
        zIndex: 999, // Set your desired z-index value       
    }),
    
    placeholder: (provided) => ({
        ...provided,
        color: 'white',
    }),

    singleValue: (provided) => ({
        ...provided,
        color: 'white',
    }),

    input: (provided) => ({
        ...provided,
        color: 'white', // Set the text color to white
    }),
};

    
    const [uploadedImage, setUploadedImage] = useState(null);

    const loadFile = (event) => {
        const fileInput = event.target;
        const image = document.getElementById('uploaded-image'); // Assume you have an element with id 'uploaded-image'
        
        if (fileInput.files.length > 0) {
            const file = fileInput.files[0];
            setUploadedImage(URL.createObjectURL(file));
            image.src = URL.createObjectURL(file);
        }
    };
    const walletData = [
        {
            id:1,
            title: 'Upload your ART here',
            image: Robotic,
            image1: Second,
            width: '290px', // Specify custom width
            height: '350px', // Specify custom height
        },
        {
            id:2,
            title: 'About NFT',
            image: Seventh,
            image1: Seventh,
            width: '510px', // Specify custom width
            height: '350px', // Specify custom height
            
        },
        {
            id:3,
            title: 'NFT Details',
            image: Third,
            image1: Third,
            width: '260px', // Specify custom width
            height: '350px', // Specify custom height
            
        },
        {
            id:4,
            title: 'NFT Credentials',
            image: Fourth,
            image1: Fourth,
            width: '430px', // Specify custom width
            height: '350px', // Specify custom height
        },
        {
            id:5,
            title: 'Basic Details',
            image: Nine,
            image1: Fifth,
            width: '360px', // Specify custom width
            height: '350px', // Specify custom height
        },
        {
            id:6,
            title: 'Social Profiles',
            image: Sixth,
            image1: Sixth,
            width: '300px', // Specify custom width
            height: '350px', // Specify custom height
        },
        {
            id:7,
            title: 'Formatic',
            image: Eight,
            image1: Eight,
            width: '445px', // Specify custom width
            height: '160px', // Specify custom height
        },
        {
            id:8,
            title: 'Pionex',
            image: Sixth,
            image1: Basic,
            width: '200px', // Specify custom width
            height: '240px', // Specify custom height
        },
        {
            id:9,
            title: 'Torus',
            image: Nine,
            image1: Nine,
            width: '390px', // Specify custom width
            height: '160px', // Specify custom height
        },

    ]

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };

    
    return (
        <>
          <section className="relative md:py-24 py-16">
          <div className="grid grid-cols-1 pb-8 text-center mb-10">
          <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span style={spanStyle}> Listing </span></h4>
          </div>
                <div className="container relative" style={{ backgroundColor: '#000', paddingBottom: '60px', paddingTop: '40px', borderRadius: '50px', }}>
                    
                    <section className="relative md:-mt-16">
                        <div className="container z-1">

                            <div className="grid grid-cols-1">
                                <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700"
                                style={{
                                    backgroundImage: `url(${Robotic6})`, // Add background image
                                    backgroundSize: 'cover', // Make sure the background image covers the entire element
                                    position: 'relative', // Add position relative for proper z-index stacking
                                    // filter: 'blur(8px)',
                                    
                                }}>
                                    <div className="registration-form text-dark text-start"
                                    
                                    >
                                        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-5 gap-6 glassmorphic1">

                                            <div>
                                                <label htmlFor="choices-catagory" className="form-label font-medium dark:text-white">Categories:</label>
                                                <div className="filter-search-form relative filter-border mt-2">
                                                    <AiOutlineAppstore className="icons"/>
                                                    <Select
                                                        className="form-input z-50 filter-input-box bg-gray-50 dark:bg-slate-801 border-0 text-white"
                                                        styles={customStyles}
                                                        options={options}
                                                        menuPlacement="auto" // or "bottom"
                                                    />
                                                </div>
                                            </div>


                                            <div>
                                                <label htmlFor="choices-min-price" className="form-label font-medium dark:text-white">Sub Categories:</label>
                                                <div className="filter-search-form relative filter-border mt-2">
                                                    <LuClock className="icons text-base"/>
                                                    <Select className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-801 border-0" styles={customStyles} options={buyOptions} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <div className="flex flex-wrap justify-center gap-8" >
                        {walletData.map((ele, index) => (
                            <div
                                key={index}
                                className="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500"
                                style={{
                                    width: ele.width,
                                    height: ele.height,
                                    margin: 'auto',
                                    backgroundImage: `url(${ele.image1})`, // Add background image
                                    backgroundSize: 'cover', // Make sure the background image covers the entire element
                                    position: 'relative', // Add position relative for proper z-index stacking
                                    // filter: 'blur(8px)',
                                    
                                }}
                            >
                                
                                <form>
                                <div className="relative glassmorphic" style={{ marginTop: ele.id === 1 || ele.id === 3 || ele.id === 5 || ele.id === 8 ? 'auto' : '-0.8rem', }}>
                                
                                    {ele.id === 1 && (
                                        <img
                                            src={uploadedImage || ele.image}
                                            id="uploaded-image"
                                            className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3"
                                            alt=""
                                        />
                                    )}
                                    {ele.id === 3 && (<img
                                      src={ele.image}
                                      className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3"
                                      alt=""
                                    />)}
                                    {ele.id === 5 && (<img
                                      src={ele.image}
                                      className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3"
                                      alt=""
                                    />)}
                                    {ele.id === 8 && (<img
                                      src={ele.image}
                                      className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3 mt-3"
                                      alt=""
                                    />)}
                                    {ele.id === 2 &&(<h5 className="text-lg font-semibold" style={{marginTop:'5px'}}>{ele.title}</h5>)}
                                    {ele.id === 1 &&(<h5 className="text-lg font-semibold mt-5">{ele.title}</h5>)}
                                    {ele.id === 3 &&(<h5 className="text-lg font-semibold mt-5">{ele.title}</h5>)}
                                    {ele.id === 5 &&(<h5 className="text-lg font-semibold mt-5">{ele.title}</h5>)}
                                    {ele.id === 4 &&(<h5 className="text-lg font-semibold" style={{marginTop:'5px'}}>{ele.title}</h5>)}
                                    {ele.id === 6 &&(<h5 className="text-lg font-semibold" style={{marginTop:'5px'}}>{ele.title}</h5>)}


                                    {/* Include the new content */}
                                    {ele.id === 2 && (
                                        <div className="md:col-span-6 col-span-12 grid grid-cols-2 gap-4 items-center mt-4">
                                            <div className="text-left">
                                                <label className="font-semibold">NFT Name<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                                <input name="name" id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="A catchy name for your NFT" />

                                                </div>

                                                <div className="text-left">
                                                <label className="font-semibold">Token ID<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                                <input name="name" id="name" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Unique Token ID of your NFT" />

                                                </div>

                                            <div className="text-left">
                                                <label className="font-semibold">Collection Name</label>
                                            </div>
                                            <div className="text-right">
                                            <input name="name" id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Specific collection, list its name." />
                                                

                                            </div>

                                            <div className="text-left">
                                                <label className="font-semibold">Description</label>
                                            </div>
                                            <div className="text-right">
                                                <textarea name="comments" id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Description :"></textarea>

                                                </div>

                                            </div>


                                    )}
                                    {ele.id === 3 && (
                                        <div className="md:col-span-6 col-span-12 grid grid-cols-2 gap-4 items-center mt-4 mb-2">
                                            <div className="text-left">
                                            <label className="font-semibold">Rarity<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <select className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2">
                                                    <option>Common</option>
                                                    <option>Rare</option>
                                                    <option>Epic</option>
                                                    <option>Legendary</option>
                                                </select>

                                                </div>

                                            <div className="text-left">
                                            <label className="font-semibold">File Type<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <select className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2">
                                                    <option>GIFs</option>
                                                    <option>Music</option>
                                                    <option>Video</option>
                                                    <option>Tech</option>
                                                </select>

                                            </div>

                                            

                                            <div className="text-left">
                                            <label className="font-semibold">Rate<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <input name="time" type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2" id="time" placeholder="$14" />

                                            </div>

                                            </div>


                                    )}

                                    {ele.id === 5 && (
                                        <div className="md:col-span-6 col-span-12 grid grid-cols-2 gap-4 items-center mt-4 mb-2">
                                            <div className="text-left">
                                            <label className="font-semibold">Current Owner<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                                <input name="name" id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="The current owner of the NFT" />

                                                </div>

                                            <div className="text-left">
                                            <label className="font-semibold">Attributes<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <select className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2">
                                                    <option>Background color</option>
                                                    <option>Character accessories</option>
                                                    <option>Animation effects</option>
                                                </select>

                                            </div>

                                            <div className="text-left">
                                            <label className="font-semibold">Minting Date</label>
                                            </div>
                                            <div className="text-right">
                                                <input name="date" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2 start" placeholder="Select date :" />

                                                </div>

                                            </div>


                                    )}

                                    {ele.id === 4 && (
                                        <div className="md:col-span-6 col-span-12 grid grid-cols-2 gap-4 items-center mt-4">
                                            <div className="text-left">
                                            <label className="font-semibold">Blockchain Network<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <select className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2">
                                                    <option>Ethereum</option>
                                                    <option>Polygon</option>
                                                    <option>Solana</option>
                                                </select>

                                                </div>

                                            <div className="text-left">
                                            <label className="font-semibold">Transfer History<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <select className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2">
                                                    <option>Canvas Craft</option>
                                                    <option>Digi Mind Forge</option>
                                                    <option>Solana</option>
                                                </select>

                                            </div>

                                            <div className="text-left">
                                            <label className="font-semibold">Royalty Percentage<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <select className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2">
                                                    <option>75 %</option>
                                                    <option>40 %</option>
                                                    <option>50 %</option>
                                                </select>

                                            </div>

                                            <div className="text-left">
                                            <label className="font-semibold">Contract Address</label>
                                            </div>
                                            <div className="text-right">
                                                <textarea name="comments" id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Contract address of your NFT :"></textarea>

                                                </div>
                                                
                                            </div>

                                    )}

                                    {ele.id === 1 && (
                                        <>
                                            <div className="preview-box flex justify-center rounded-md shadow dark:shadow-gray-800 overflow-hidden bg-gray-50 dark:bg-slate-800 text-slate-400 p-2 text-center small mt-5">Supports JPG, PNG and MP4 videos. Max file size : 10MB.</div>
                                            <input type="file" id="input-file" name="input-file" accept="image/*" hidden onChange={loadFile} />
                                            <label className="btn-upload btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full w-full mt-6 cursor-pointer mb-1" htmlFor="input-file">Upload Image</label>
                                        </>
                                    )}

                                    {ele.id === 6 && (
                                        <div className="md:col-span-6 col-span-12 grid grid-cols-2 gap-4 items-center mt-4 mb-2">
                                            <div className="text-left">
                                                <label className="font-semibold">Twitter<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right">
                                            <div className="form-icon relative">
                                            <Twitter className="w-4 h-4 absolute top-3 start-4"></Twitter>
                                            <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0" placeholder="Twitter Profile Name" id="twitter_name" name="name" />
                                            </div>

                                            </div>

                                            <div className="text-left mt-2">
                                            <label className="font-semibold">Facebook<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right mt-2">
                                            <div className="form-icon relative">
                                            <Facebook className="w-4 h-4 absolute top-3 start-4"></Facebook>
                                            <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0" placeholder="Facebook Profile Name" id="facebook_name" name="name" />
                                            </div>

                                            </div>

                                            <div className="text-left mt-2">
                                            <label className="font-semibold">Instagram<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right mt-2">
                                            <div className="form-icon relative">
                                            <Instagram className="w-4 h-4 absolute top-3 start-4"></Instagram>
                                            <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0" placeholder="Instagram Profile Name" id="insta_name" name="name" />
                                            </div>

                                            </div>

                                            <div className="text-left mt-2">
                                            <label className="font-semibold">Linkedin<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right mt-2">
                                            <div className="form-icon relative">
                                            <Linkedin className="w-4 h-4 absolute top-3 start-4"></Linkedin>
                                            <input type="text" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0" placeholder="Linkedin Profile Name" id="linkedin_name" name="name" />
                                            </div>

                                            </div>

                                            <div className="text-left mt-2">
                                            <label className="font-semibold">Youtube<span className="text-red-600">*</span></label>
                                            </div>
                                            <div className="text-right mt-2">
                                            <div className="form-icon relative">
                                            <Youtube className="w-4 h-4 absolute top-3 start-4"></Youtube>
                                            <input type="url" className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-red-600 dark:focus:border-violet-600 focus:ring-0" placeholder="Youtube url" id="you_url" name="url" />
                                            </div>

                                            </div>

                                            </div>


                                    )}

                                    {ele.id === 7 && (
                                        <div className="md:col-span-6 col-span-12 grid grid-cols-2 gap-4 items-center mt-4">
                                        <div className="text-left">
                                        <label className="font-semibold">Unlockable Content<span className="text-red-600">*</span></label>
                                        </div>
                                        <div className="text-right">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti1" />
                                        <label className="form-check-label" htmlFor="noti1"></label>

                                            </div>
                                        <div className="text-left mt-5">
                                        <label className="font-semibold">Community Guidelines<span className="text-red-600">*</span></label>
                                        </div>
                                        <div className="text-right mt-5">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti4" />
                                        <label className="form-check-label" htmlFor="noti4"></label>

                                        </div>

                                        


                                        </div>

                                    )}
                                    {ele.id === 8 && (
                                        <>
                                        <div className='mt-5 mb-2'>
                                            <Link to="/createNFT" onClick={toggleModal} className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full p-2">Create Item</Link>
                                                {/* Modal */}
                                                <Modal
                                                    isOpen={isModalOpen}
                                                    onRequestClose={toggleModal}
                                                    contentLabel="Popup"
                                                    className="modal"
                                                    overlayClassName="overlay">
                                                        
                                                    {/* Content of the modal */}
                                                    <div className="popup-content" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '120px' }}>
                                                        <p>Your NFT has been successfully created</p>
                                                        <br />
                                                        <button className='btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full' onClick={toggleModal}>
                                                            OK
                                                        </button>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </>

                                    )}
                                    {ele.id === 9 && (
                                        <div className="md:col-span-6 col-span-12 grid grid-cols-2 gap-4 items-center mt-3 ">
                                        <div className="text-left ">
                                        <label className="font-semibold">Legal Information<span className="text-red-600">*</span></label>
                                        </div>
                                        <div className="text-right ">
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" id="noti3" />
                                                        <label className="form-check-label" htmlFor="noti3"></label>

                                        </div>
                                        <div className="text-left mt-5">
                                        <label className="font-semibold">Become A Creator<span className="text-red-600">*</span></label>
                                        </div>
                                        <div className="text-right mt-5" >
                                        <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti2" />
                                                        <label className="form-check-label" htmlFor="noti2"></label>

                                        </div>
                                        

                                        </div>

                                    )}
                                </div>
                                </form>
                            </div>
                        ))}
                        
                    </div>

                </div>
            </section>       
                 
        </>
    )
}

