import React, { useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import HomeSecond from '../home-second';
// import img1 from '../../assets/images/AINew.jpeg'
import CommunityList from '../../pages/explore/CommunityList'
import AIModelsList1 from '../../pages/explore/AI-Models-List1'
import AIModelsList2 from '../../pages/explore/AI-Models-List2'
// import AIModelsList from '../../components/Testimonial';
import {MdOutlineArrowForward, RxQuestionMarkCircled, PiBookBookmarkBold, HiOutlineCog6Tooth,SiAboutdotme} from "../../assets/icons/vander"

export default function CommunityDao() {
const img1 = 'https://lirp.cdn-website.com/d5d4bcc9/dms3rep/multi/opt/DALL-E+2023-11-22+15.04.47+-+A+professional+and+engaging+blog+banner+for+a+business+coaching+blog-+visually+representing+the+concept+of+business+growth+and+entrepreneurship.+The+b-1920w.png'
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic6">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">Community <span style={spanStyle}> & DAO</span></h4>
                        {/* <p className="text-slate-400 max-w-xl mx-auto">The visual concept for the "AI Models Marketplace" section of the Joy marketplace website has been created. It features a sleek, user-friendly, and tech-oriented design, with distinct areas for browsing, uploading, and trading AI models. The design includes visuals that represent the exploration of AI models, the upload functionality, and secure trading, facilitated by blockchain technology. Clear navigation is provided for each section: 'Explore Cutting-Edge AI Models', 'Share Your AI Creations', and 'Trade AI Models Securely', ensuring a smooth user experience.</p> */}

                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> 
            
                <div style={{marginTop:'-60px'}}><CommunityList/></div>
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
