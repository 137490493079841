import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import image from '../../assets/images/items/3.gif';
import image1 from '../../assets/images/avatar/1.jpg';
import image3 from '../../assets/images/items/2.gif';
import image4 from '../../assets/images/items/1.jpg';
import image5 from '../../assets/images/items/2.jpg';
import image6 from '../../assets/images/items/1.gif';
import Navbar from '../../components/navbar';
import bg1 from '../../assets/images/first.png';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { data } from '../../data/data';
import { IoMdClose, BsExclamationOctagon } from '../../assets/icons/vander';

export default function AIModelsList() {
  const params = useParams();
  const id = params.id;
  const creater = data.find((creatorr) => creatorr.id === parseInt(id));

  const [activeIndex, setIndex] = useState(0);
  const [placeBid, setPlaceBid] = useState(false);
  const [buyNow, setBuyNow] = useState(false);

  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);

  const spanStyle = {
    background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    display: 'inline-block',
  };

  return (
    <>
      <section className="relative pt-28 md:pb-24 pb-16">
        <div className="container">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-7 lg:me-8">
              <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4">
                <span  style={spanStyle}>
                Explore Cutting-Edge{' '}
                </span><br></br>
                AI Models
              </h4>
              {/* <h5 className="md:text-2xl text-xl font-semibold">{creater?.title ? creater?.title :"Probably A Label #3277"}</h5> */}
              <p className="text-slate-400 mt-4">Dive into our diverse collection of AI models. Whether you're seeking advanced machine learning algorithms or innovative neural networks, our marketplace has something for every AI enthusiast. Browse through various categories and find the perfect fit for your project.
              </p>
              
              
            </div>

            <div className="lg:col-span-5">
              <img
                src={creater?.image ? creater?.image : bg1}
                className="rounded-md shadow dark:shadow-gray-700"
                alt=""
              />
            </div>
          </div>
        </div>

            
            
      </section>
    </>
  );
}
