import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import image from '../../assets/images/items/3.gif';
import image1 from '../../assets/images/avatar/1.jpg';
import image3 from '../../assets/images/items/2.gif';
import image4 from '../../assets/images/items/1.jpg';
import image5 from '../../assets/images/items/2.jpg';
import image6 from '../../assets/images/items/1.gif';
import Navbar from '../../components/navbar';
import bg1 from '../../assets/images/second.png';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { data } from '../../data/data';
import { IoMdClose, BsExclamationOctagon } from '../../assets/icons/vander';

export default function AIModelsList1() {
  const params = useParams();
  const id = params.id;
  const creater = data.find((creatorr) => creatorr.id === parseInt(id));

  const [activeIndex, setIndex] = useState(0);
  const [placeBid, setPlaceBid] = useState(false);
  const [buyNow, setBuyNow] = useState(false);

  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);

  const spanStyle = {
    background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    display: 'inline-block',
  };

  return (
    <>
      <section className="relative pt-28 md:pb-24 pb-16" style={{marginTop: '-100px'}}>
        <div className="container">
            
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
            
            <div className="lg:col-span-5">
              <img
                src={creater?.image ? creater?.image : bg1}
                className="rounded-md shadow dark:shadow-gray-700"
                alt=""
              />
            </div>

            <div className="lg:col-span-7 lg:ms-8">
              <h4 className="font-bold lg:leading-snug leading-snug text-3xl lg:text-5xl mb-4">
                <span style={spanStyle}>
                Share Your{' '}
                </span> <br></br>
                AI Creations
              </h4>
              {/* <h5 className="md:text-2xl text-xl font-semibold">{creater?.title ? creater?.title :"Probably A Label #3277"}</h5> */}
              <p className="text-slate-400 mt-4">Got an AI model that can change the game? Upload it to Joy! Our platform makes it easy for you to showcase your work to a global audience. Share your innovations and gain recognition in the AI community
              </p>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
