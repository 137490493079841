import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Switcher from '../components/switcher'
import { Link } from 'react-router-dom'
import img1 from '../assets/images/legal.jpeg'
import {MdOutlineArrowForward} from "../assets/icons/vander"

export default function LegalPrivacy() {
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic6">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">Legal & <span style={spanStyle}> Privacy</span></h4>
                        <p className="text-slate-400 max-w-xl mx-auto">Your Trust, Our Commitment</p>

                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> 

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h5 className="text-xl font-semibold mb-4">Understanding Our Legal Framework :</h5>
                                <p className="text-slate-400">Our legal policies are designed to ensure a fair, secure, and transparent marketplace. Familiarize yourself with our  <Link  className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">Terms of Service,</Link> <Link  className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-ou-in">User Agreement,</Link> and other legal guidelines that govern your use of Joy</p>
                                {/* <p className="text-slate-400">In the 1960s, the text suddenly became known beyond the professional circle of typesetters and layout designers when it was used for Letraset sheets (adhesive letters on transparent film, popular until the 1980s) Versions of the text were subsequently included in DTP programmes such as PageMaker etc.</p> */}
                                {/* <p className="text-slate-400">There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories.</p> */}

                                {/* <h5 className="text-xl font-semibold mb-4 mt-8">We use your information to :</h5>
                                <ul className="list-none text-slate-400 mt-4">
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Create your own skin to match your brand</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Digital Marketing Solutions for Tomorrow</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Our Talented & Experienced Marketing Agency</li>
                                    <li className="flex items-center mt-2"><MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>Create your own skin to match your brand</li>
                                </ul> */}

                                <h5 className="text-xl font-semibold mb-4 mt-8">Your Privacy Matters :</h5>
                                <p className="text-slate-400">At Joy, we prioritize your privacy. Learn about how we handle your data with our <Link  className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">Privacy Policy.</Link> We're committed to maintaining your trust with stringent data protection and privacy measures</p>
                                <div className="mt-8">
                                    <Link to="#" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full">Print</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
