import React from 'react'
import { Link } from 'react-router-dom';
import { Hexagon } from 'react-feather';
import {TbSitemap, LuLayers, TbCameraPlus} from '../assets/icons/vander'
import ItemDetail from '../pages/explore/item-detail';
import img from '../assets/images/AI5.jpg';
import CreatorThree from '../components/creator-three';
import img1 from '../assets/images/AI_ROBO_.png';
import ItemDetail2 from '../pages/explore/item-detail2';
import ItemDetail3 from '../pages/explore/item-detail3';


export default function Feature() {
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    
    return (
        <>
        <section className="relative lg:pt-24 pt-[74px] overflow-hidden">

                {/* <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden">
                    <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-violet-500 dark:bg-violet-500" style={{backgroundImage:`url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center'}}>
                        <div className="container" >
                            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                                <div className="md:col-span-7">
                                    <div className="md:me-6">
                                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span style={spanStyle}>Who </span> We Are ?</h4>
                                        <p className="text-lg max-w-xl">At Joy Marketplace, we believe that living sustainably should be enjoyable and accessible to everyone. Our platform connects you with a wide range of eco-friendly products, services, and experiences, empowering you to make choices that benefit both the planet and your well-being.</p>

                                        <div className="mt-6">
                                            <Link to="#" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2">About Us</Link>
                                            
                                        </div>
                                    </div>
                                    
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div> */}

                
                <div style={{marginTop:"70px"}}>
                    <CreatorThree title="Best Listing of"/>
                </div>

                <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                </div>
            
            {/* <div className="fixed blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-violet-600/20 dark:from-red-600/40 dark:to-violet-600/40"></div> */}
            
            <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/20 dark:after:bg-violet-600/40 after:bottom-[0] after:end-[15%] after:-z-1 after:rounded-full after:animate-ping"></div>
            
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">

            {/* <span className="absolute blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/40 to-violet-600/40 dark:from-red-600/60 dark:to-violet-600/60"></span> */}
            <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    {/* Component 1 */}
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <LuLayers/>
                        </div>
                    </div>

                    <div className="mt-6">
                        
                        <h6 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span  style={spanStyle}></span>Our Vision</h6>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3 p-5" style={{textAlign:'justify', textJustify:'inter-word'}}>We envision a world where AI and blockchain technology come together to create a sustainable, inclusive, and thriving digital ecosystem. Joy aims to be at the forefront of this integration, fostering a community where innovation, learning, and collaboration are the cornerstones. Our platform is designed to be a hub for AI enthusiasts, developers, artists, and curious minds, united in the pursuit of creating, sharing, and benefiting from AI and digital art. Through Joy, we seek to inspire a future where technology enhances creativity and every individual has the opportunity to participate in the digital revolution.</p>
                    </div>
                </div>

                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center" >
                    {/* Component 2 */}
                    
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <TbSitemap/>
                        </div>
                    </div>
                    

                    <div className="mt-6">
                    <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/20 dark:after:bg-violet-600/40 after:bottom-[0] after:end-[15%] after:-z-1 after:rounded-full after:animate-ping"></div>

                        <h6 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span  style={spanStyle}>Our Mission at Joy </span></h6>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3 p-5" style={{textAlign:'justify', textJustify:'inter-word'}}>Joy is more than a marketplace; it’s a vision to revolutionize how we interact with AI and digital art. Our mission is to democratize AI development and trading, making it accessible and rewarding for everyone. We believe in empowering creators and innovators by providing them with the tools, platform, and community support to bring their ideas to life. Joy merges technology with creativity, offering a unique space where AI models are not just tools but also art, represented as NFTs.</p>
                    </div>
                </div>

            </div>

            
            

            <div className="grid grid-cols-1 text-center" style={{marginTop:'100px'}}>
            <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>                
            <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span  style={spanStyle}>Love and Vice </span> Scoring System</h4>
                <p className="text-slate-400 max-w-xl mx-auto">Embrace the spirit of positive reinforcement and responsible gaming with our unique Love and Vice Scoring System. Earn Love points for participating in sustainable activities and contributing to the platform, while managing your Vice score to maintain a healthy balance.</p>
                <div className="mt-6">
                    <Link to="#" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2">Learn About Love And Vice Scores</Link>
                </div>
                
            </div>

            {/* <ItemDetail/> */}
            <ItemDetail3/>
            <ItemDetail2/>    
            
        </section>

        <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden">
        <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-violet-700/10 dark:bg-violet-600/20" style={{backgroundImage:`url(${img})`,backgroundSize: 'cover', backgroundPosition: 'center',}}>
            <div className="container" >
                <div className="grid grid-cols-1 text-center" >               
                   <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-4xl mb-4">Ready to embrace the joy of sustainable living? Sign up today and start your journey towards a greener, happier future.</h1>
                   <div className="mt-6">
                       <Link to="#" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2">Create Your Account</Link>
                   </div>
              </div>
          </div>
        </div>
        </div>
        </>
    )
}
