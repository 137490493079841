import React, { useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import HomeSecond from '../home-second';
// import img1 from '../../assets/images/demo3.jpeg'
import FAQuestions from '../../../src/pages/helpcenter/FAQ-Questions'
import AIModelsList1 from '../../pages/explore/AI-Models-List1'
import AIModelsList2 from '../../pages/explore/AI-Models-List2'
// import AIModelsList from '../../components/Testimonial';
import { Hexagon } from 'react-feather';
import {TbSitemap, LuLayers, TbCameraPlus,TbRobot,TbHelp} from "../../assets/icons/vander"

export default function SupportFAQ() {
const img1 = 'https://cognivix.files.wordpress.com/2023/11/dallc2b7e-2023-11-20-10.51.57-design-a-wide-modern-banner-image-for-a-privacy-policy-webpage-with-dimensions-suitable-for-a-header.-the-image-should-have-a-flat-design-style-with.png?w=1024'
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic6">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">Support & <span style={spanStyle}> FAQs</span></h4>
                        <p className="text-slate-400 max-w-xl mx-auto">Here to Help at Joy. Get the Support You Need</p>

                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> 
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">

            {/* <span className="absolute blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/40 to-violet-600/40 dark:from-red-600/60 dark:to-violet-600/60"></span> */}
            <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                    {/* Component 1 */}
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <TbHelp/>
                        </div>
                    </div>

                    <div className="mt-6">
                    <h6 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4"><span  style={spanStyle}>Find Answers Quickly </span></h6>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3 p-5" style={{textAlign:'justify', textJustify:'inter-word'}}>Browse through our FAQs for quick answers to common questions. From how to get started, to trading AI models and using Copilot, our FAQs cover a wide range of topics, providing you with immediate solutions and guidance.</p>
                        
                    </div>
                </div>

                <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center" >
                    {/* Component 2 */}
                    
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <TbRobot/>
                        </div>
                    </div>
                    

                    <div className="mt-6">
                    <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/20 dark:after:bg-violet-600/40 after:bottom-[0] after:end-[15%] after:-z-1 after:rounded-full after:animate-ping"></div>

                    <h6 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mb-4"><span  style={spanStyle}>We're Here to </span> Assist</h6>
                        <p className="text-slate-400 transition duration-500 ease-in-out mt-3 p-5" style={{textAlign:'justify', textJustify:'inter-word'}}>Encounter an issue or have a question? Our Support team is ready to help. Reach out through our contact form, email, or chat for prompt assistance. We're committed to ensuring a smooth experience on Joy</p>
                    </div>
                </div>

            </div>
            <div>
            <h6 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-5xl mt-10 text-center"><span  style={spanStyle}>Joy Marketplace FAQs</span></h6>
            <FAQuestions/>
            </div>
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
