import React, { useEffect, useState } from 'react'

import logo_icon_50 from '../assets/images/logo-icon-50.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
// import image from '../assets/images/client/05.jpg'; 
import { Link } from "react-router-dom";
import {LuSearch, PiWalletBold, AiOutlineCopy, AiOutlineUser, LuSettings, LiaSignOutAltSolid} from "../assets/icons/vander"


export default function Navbar() {
    const [isDropdown, openDropdown] = useState(true);
    const [isOpen, setMenu] = useState(true);
    const image = 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/UUID-cl8j7kpbw30430kxqy4a2kw1yt/public'

    useEffect(() => {
        activateMenu();
    });

    window.addEventListener("scroll", windowScroll);
    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }

    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };
    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }

    const metamask = async () => {
        try {
            //Basic Actions Section
            const onboardButton = document.getElementById('connectWallet')

            //   metamask modal
            const modal = document.getElementById('modal-metamask')
            const closeModalBtn = document.getElementById('close-modal')

            //   wallet address
            const myPublicAddress = document.getElementById('myPublicAddress')

            //Created check function to see if the MetaMask extension is installed
            const isMetaMaskInstalled = () => {
                //Have to check the ethereum binding on the window object to see if it's installed
                const { ethereum } = window
                return Boolean(ethereum && ethereum.isMetaMask)
            }

            const onClickConnect = async () => {
                if (!isMetaMaskInstalled()) {
                    //meta mask not installed
                    modal.classList.add('show')
                    modal.style.display = 'block'
                    return
                }
                try {
                    // eslint-disable-next-line no-undef
                    await ethereum.request({ method: 'eth_requestAccounts' })
                    // eslint-disable-next-line no-undef
                    const accounts = await ethereum.request({ method: 'eth_accounts' })
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                } catch (error) {
                    console.error(error)
                }
            }

            const closeModal = () => {
                modal.classList.remove('show')
                modal.style.display = 'none'
            }

            if (isMetaMaskInstalled()) {
                // eslint-disable-next-line no-undef
                const accounts = await ethereum.request({ method: 'eth_accounts' })
                if (!!accounts[0]) {
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                }
            }

            onboardButton.addEventListener('click', onClickConnect)
            closeModalBtn.addEventListener('click', closeModal)
        } catch (error) { }
    }
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
      };
    return (
        <>
            <nav id="topnav" className="defaultscroll is-sticky">
                <div className="container">
                    {/* <!-- Logo container--> */}
                    <Link className="logo ps-0" to="/">
                        <img src={logo_icon_50} className="inline-block sm:hidden" alt="" />
                        <div className="sm:block hidden">
                            <img src={logo_dark} className="inline-block dark:hidden h-7" alt="" />
                            <img height={'3.75rem !important'} width={70} src={logo_white} className="hidden dark:inline-block h-77" alt="" />
                        </div>
                    </Link>
                    {/* <div className="menu-extras ">
                        <div className="menu-item  ">
                            <!-- Mobile menu toggle-->
                            <Link to="#" className="navbar-toggle " id="isToggle" onClick={toggleMenu}>
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </div>
                    </div> */}
                    

                    {/* <!--Login button Start--> */}
                    <ul className="buy-button list-none mb-0">

                        {/* <li className="inline-block mb-0">
                            <div className="form-icon relative">
                                <LuSearch className="text-lg absolute top-1/2 -translate-y-1/2 start-3"/>
                                <input type="text" className="form-input sm:w-44 w-28 ps-10 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-3xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 bg-white" name="s" id="searchItem" placeholder="Search..." />
                            </div>
                        </li> */}

                        {/* <li className="inline-block ps-1 mb-0">
                            <Link to="#" onClick={metamask} id="connectWallet" className="btn btn-icon rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><PiWalletBold/></Link>
                        </li> */}

                        {/* <li className="dropdown inline-block relative ps-1">
                            <button
                            //
                             onClick={() => openDropdown(!isDropdown)} 
                            //
                            data-dropdown-toggle="dropdown" className="dropdown-toggle btn btn-icon rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white inline-flex" type="button">
                                <img src={image} className="rounded-full" alt="profile" />
                            </button>

                            <div className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 ${isDropdown ? 'hidden' : 'block'}`} >
                                <div className="relative">
                                    <div className="py-8 bg-gradient-to-tr from-violet-600 to-red-600"></div>
                                    <div className="absolute px-4 -bottom-7 start-0">
                                        <div className="flex items-end">
                                            <img src={image} className="rounded-full w-10 h-w-10 shadow dark:shadow-gray-700" alt="profile" />

                                            <span className="font-semibold text-[15px] ms-1">Jenny Jimenez</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-10 px-4">
                                    <h5 className="font-semibold text-[15px]">Wallet:</h5>
                                    <div className="flex items-center justify-between">
                                        <span className="text-[13px] text-slate-400">qhut0...hfteh45</span>
                                        <Link to="#" className="text-violet-600"><AiOutlineCopy/></Link>
                                    </div>
                                </div>

                                <div className="mt-4 px-4">
                                    <h5 className="text-[15px]">Balance: <span className="text-violet-600 font-semibold">0.00045ETH</span></h5>
                                </div>

                                <ul className="py-2 text-start">
                                    <li>
                                        <Link to="/creator-profile" className="inline-flex items-center text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600"><AiOutlineUser className="text-[16px] align-middle me-1"/> Profile</Link>
                                    </li>
                                    <li>
                                        <Link to="/creator-profile-edit" className="inline-flex items-center text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600"><LuSettings className="text-[16px] align-middle me-1"/> Settings</Link>
                                    </li>
                                    <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                                    <li>
                                        <Link to="/login" className="inline-flex items-center text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600"><LiaSignOutAltSolid className="text-[16px] align-middle me-1"/> Logout</Link>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                    </ul>

                    <nav role='navigation'>
                      <div id="menuToggle">
                        <input type="checkbox" />
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul id="menu">
                          <a href="/"><li className="has-submenu parent-menu-item"><Link to="/">Home</Link></li></a>
                          <a href="/about-us"><li><Link to="/about-us" className="sub-menu-item-about">About Us</Link></li></a>
                          <a href="#"><li><Link to="/AI-Models-Marketplace" className="sub-menu-item-about">AI Models Marketplace</Link></li></a>
                          <a href="#"><li><Link to="/NFT-Gallery" className="sub-menu-item-about">NFT Gallery</Link></li></a>
                          <a href="#"><li><Link to="/Community-DAO" className="sub-menu-item-about">Community & DAO</Link></li></a>
                          <a href="#"><li><Link to="/How-It-Works" className="sub-menu-item-about">How It Works</Link></li></a>
                          <a href="#"><li><Link to="/Education-Resource" className="sub-menu-item-about">Educational Resources</Link></li></a>
                          <a href="#"><li className="has-submenu parent-parent-menu-item"><Link to="/Support-FAQ">Support & FAQs</Link></li></a>
                          <a href="#"><li><Link to="/Blogs-News" className="sub-menu-item-about">Blog/News</Link></li></a>
                          <a href="#"><li><Link to="/contact-us" className="sub-menu-item-about">Contact Us</Link></li></a>
                          <a href="#"><li><Link to="/Legal-Privacy" className="sub-menu-item-about">Legal & Privacy</Link></li></a>
                          <a href="#"><li><Link to="/createNFT" className="sub-menu-item-create">User Dashboard</Link></li></a>
                        </ul>
                      </div>
                    </nav>

                    <div id="navigation" style={{marginLeft:'55%'}} className={`${isOpen === true ? 'hidden' : 'block'}`}>
                        <ul className="navigation-menu justify-between" style={{color:{spanStyle},}}>
                            <li className="has-submenu parent-menu-item">
                                <Link to="/">Home</Link>
                                {/* <span className="menu-arrow"></span>
                                <ul className="submenu">
                                    <li><Link to="/index" className="sub-menu-item">Home One</Link></li>
                                    <li><Link to="/index-two" className="sub-menu-item">Home Two</Link></li>
                                    <li><Link to="/index-three" className="sub-menu-item">Home Three</Link></li>
                                    <li><Link to="/index-four" className="sub-menu-item">Home Four <span className="bg-gray-50 dark:bg-slate-800 text-[10px] shadow shadow-gray-300 dark:shadow-gray-700 font-bold px-2.5 py-0.5 rounded h-5 ms-1">Light</span></Link></li>
                                    <li><Link to="/index-five" className="sub-menu-item">Home Five <span className="bg-gray-50 dark:bg-slate-800 text-[10px] shadow shadow-gray-300 dark:shadow-gray-700 font-bold px-2.5 py-0.5 rounded h-5 ms-1">Light</span></Link></li>
                                    <li><Link to="/index-six" className="sub-menu-item">Home Six <span className="bg-gray-50 dark:bg-slate-800 text-[10px] shadow shadow-gray-300 dark:shadow-gray-700 font-bold px-2.5 py-0.5 rounded h-5 ms-1">Light</span></Link></li>
                                    <li><Link to="/index-seven" className="sub-menu-item">Home Seven </Link></li>
                                    <li><Link to="/index-eight" className="sub-menu-item">Home Eight <span className="bg-gray-50 dark:bg-slate-800 text-[10px] shadow shadow-gray-300 dark:shadow-gray-700 font-bold px-2.5 py-0.5 rounded h-5 ms-1">Light</span></Link></li>
                                    <li><Link to="/index-nine" className="sub-menu-item">Home Nine </Link></li>
                                    <li><Link to="/index-ten" className="sub-menu-item">Home Ten <span className="bg-gray-50 dark:bg-slate-800 text-[10px] shadow shadow-gray-300 dark:shadow-gray-700 font-bold px-2.5 py-0.5 rounded h-5 ms-1">Light</span></Link></li>
                                </ul> */}
                            </li>
                            <li><Link to="/about-us" className="sub-menu-item-about">About Us</Link></li>
                            <li><Link to="/AI-Models-Marketplace" className="sub-menu-item-about">AI Models Marketplace</Link></li>
                            <li><Link to="/NFT-Gallery" className="sub-menu-item-about">NFT Gallery</Link></li>
                            <li><Link to="/Community-DAO" className="sub-menu-item-about">Community & DAO</Link></li>
                            <li><Link to="/How-It-Works" className="sub-menu-item-about">How It Works</Link></li>
                            <li><Link to="/Education-Resource" className="sub-menu-item-about">Educational Resources</Link></li>
                            

                            <li className="has-submenu parent-parent-menu-item">
                                <Link to="/Support-FAQ">Support & FAQs</Link>
                                {/* <span className="menu-arrow"></span>
                                <ul className="submenu">
                                    <li><Link to="/explore-one" className="sub-menu-item"> Explore One</Link></li>
                                    <li><Link to="/explore-two" className="sub-menu-item"> Explore Two</Link></li>
                                    <li><Link to="/explore-three" className="sub-menu-item"> Explore Three</Link></li>
                                    <li><Link to="/auction" className="sub-menu-item">Live Auction</Link></li>
                                    <li><Link to="/item-detail" className="sub-menu-item"> Item Detail</Link></li>
                                    <li><Link to="/activity" className="sub-menu-item"> Activities</Link></li>
                                    <li><Link to="/collections" className="sub-menu-item">Collections</Link></li>
                                    <li><Link to="/upload-work" className="sub-menu-item">Upload Works</Link></li>
                                </ul> */}
                            </li>



                            <li><Link to="/Blogs-News" className="sub-menu-item-about">Blog/News</Link></li>
                            <li><Link to="/contact-us" className="sub-menu-item-about">Contact Us</Link></li>
                            <li><Link to="/Legal-Privacy" className="sub-menu-item-about">Legal & Privacy</Link></li>
                            <li><Link to="/createNFT" className="sub-menu-item-create">User Dashboard</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
            

        </>
    )
}
